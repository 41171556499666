import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'identification',
    component: () => import('../vues/pages/IdentificationPage.vue')
  },
  {
    path: '/directory',
    name: 'directory',
    component: () => import('../vues/pages/directoryPage.vue')
  },
  {
    path: '/board',
    name: 'board',
    component: () => import('../vues/pages/boardPage.vue'),
  },
  {
    path: '/board/carbon',
    name: 'carbon',
    component: () => import('../vues/pages/carbonFootPrint.vue'),
  },
  {
    path: '/board/carbon/sites/:project_id',
    name: 'site',
    component: () => import('../vues/pages/scopesPage.vue'),
  },
  {
    path: '/board/carbon/sites/:project_id/scope/:scope_id',
    name: 'scope',
    component: () => import('../vues/pages/scopePage.vue'),
  },
  {
    path: '/board/carbon/sites/:project_id/scope/:scope_id/:module_id',
    name: 'emission-source',
    component: () => import('../vues/pages/emissionSource.vue'),
  },
  {
    path: '/board/carbon/:project_id/scope/:scope_id/add',
    name: 'add-emission-source',
    component: () => import('../vues/pages/addEmissionSource.vue'),
  },
  {
    path: '/board/carbon/sites/:project_id/scope/:scope_id/providers',
    name: 'providers',
    component: () => import('../vues/pages/providersPage.vue'),
  },
  {
    path: '/board/carbon/sites/:project_id/scope/:scope_id/waste-treatment',
    name: 'waste-treatment',
    component: () => import('../vues/pages/wasteTreatmentPage.vue'),
  },
  {
    path: '/board/carbon/sites/:project_id/scope/:scope_id/waste-treatment/:module_id',
    name: 'emission-source-waste-treatment',
    component: () => import('../vues/pages/emissionSource.vue'),
  },
  {
    path: '/board/carbon/:project_id/scope/:scope_id/waste-treatment/add',
    name: 'add-emission-source-waste-treatment',
    component: () => import('../vues/pages/addEmissionSource.vue'),
  },
  {
    path: '/board/carbon/sites/:project_id/scope/:scope_id/customers',
    name: 'customers',
    component: () => import('../vues/pages/customersPage.vue'),
  },
  {
    path: '/board/carbon/sites/:project_id/scope/:scope_id/customers/:module_id',
    name: 'emission-source-customers',
    component: () => import('../vues/pages/emissionSource.vue'),
  },
  {
    path: '/board/carbon/:project_id/scope/:scope_id/customers/add',
    name: 'add-emission-source-customers',
    component: () => import('../vues/pages/addEmissionSource.vue'),
  },
  {
    path: '/board/carbon/sites/:project_id/scope/:scope_id/providers/:provider_id',
    name: 'provider',
    component: () => import('../vues/pages/providerPage.vue'),
  },
  {
    path: '/board/carbon/sites/:project_id/scope/:scope_id/providers/:provider_id/:module_id',
    name: 'emission-source-provider',
    component: () => import('../vues/pages/emissionSource.vue'),
  },
  {
    path: '/board/carbon/:project_id/scope/:scope_id/providers/:provider_id/add',
    name: 'add-emission-source-provider',
    component: () => import('../vues/pages/addEmissionSource.vue'),
  },
  {
    path: '/board/carbon/sites/:project_id/scope/:scope_id/employees',
    name: 'employees',
    component: () => import('../vues/pages/employeesPage.vue'),
  },
  {
    path: '/board/carbon/sites/:project_id/scope/:scope_id/employees/:module_id',
    name: 'emission-source-employees',
    component: () => import('../vues/pages/emissionSource.vue'),
  },
  {
    path: '/board/results',
    name: 'results',
    component: () => import('../vues/pages/carbonResultsPage.vue'),
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
