import { createApp } from 'vue'
// import {createStore} from 'vuex'
import App from './App.vue'
import router from './router'
import axios from 'axios'
import setAuthHeader from './authorization'
import './assets/main.css';
// import { createAuth0 } from '@auth0/auth0-vue'

// import Vue from 'vue';
import { createI18n } from "vue-i18n";

// /* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

// /* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core'

// /* import specific icons */
import { faPenToSquare } from '@fortawesome/free-solid-svg-icons'
// import { faTrashCan } from '@fortawesome/free-regular-svg-icons'


// /* add icons to the library */
library.add(faPenToSquare)

const instance = axios.create({
  // baseURL: 'https://api-dev.physis-solutions.com',
  baseURL: 'https://betaapi.physis-solutions.com',
  // baseURL: process.env.VUE_APP_API_URL,
  // baseURL: 'http://127.0.0.1:5000',
  contentType: 'application/json'

});

if(localStorage.token){
    setAuthHeader(localStorage.token);
}else{
    setAuthHeader(false);
}

// import translations
import fr from "./locales/fr.json";
import en from "./locales/en.json";
import es from "./locales/es.json";

// configure i18n
const i18n = createI18n({
  locale: "fr",
  fallbackLocale: "fr",
  messages: { fr, en, es },
});

// create and start the app
const app = createApp(App);
app.component('font-awesome-icon', FontAwesomeIcon)
app.use(router);
app.use(i18n);
// app.use(
//   createAuth0({
//     domain: 'dev-paxkr6w6deleq164.us.auth0.com',
//     clientId: 'R7ispQSf1GI9TcoYSDSzjLfppTgnpjco',
//     authorizationParams: {
//       redirect_uri: 'http://localhost:8080'
//     }
//   })
// );
app.mount("#app");

export default instance;
