<template>
  <div id="form-button" @click="openForm()">
    <font-awesome-icon :icon="['fas', 'pen-to-square']" />
  </div>
</template>
<script>
export default {
  name: 'formButton',
  methods: {
    openForm() {
      if (this.$i18n.locale == 'fr') {
        window.open('https://docs.google.com/forms/d/e/1FAIpQLSdmhBEzqt7zz3yY_DvN2Z7q8xNf8J-bFwMTSQOtH6czqdLbtQ/viewform?usp=sf_link', '_blank');
      } else {
        window.open('https://docs.google.com/forms/d/e/1FAIpQLSc1NhF0klyr3nZuHIs6DhEjo7NFLN3jje2BWtkFrDqkbG98SQ/viewform', '_blank');
      }
    }
  }
}
</script>
